<template>
  <Bar style="width: 100%; height: 100%" :chart-data="data" :chart-options="options" />
</template>
<script>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  props: {
    data: Object,
    title: String,
  },
  name: 'App',
  components: {
    Bar,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
}
</script>
