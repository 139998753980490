<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Plot </span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2" @click="getPlotStats">
        <v-icon>
          {{ icons.mdiReload }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-6 mt-3">
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="4" lg="2" class="d-flex align-center">
          <v-avatar
            style="cursor: pointer"
            @click="onLandTypeClicked(data.name)"
            size="55"
            :color="data.color"
            rounded
            class="elevation-1"
          >
            <v-icon dark color="white" size="35">
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.name }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiReload,
  mdiMapMarkerOutline,
  mdiShieldAccountOutline,
  mdiMapMarkerRadius,
  mdiMapMarker,
  mdiEarthOff,
  mdiChartBar,
  mdiGavel,
} from '@mdi/js'
import { getPlotStats } from '@/api/dashboard'
import { LargeTextCellEditor } from 'ag-grid-community'
export default {
  data() {
    return {
      icons: {
        mdiReload,
      },
      statisticsData: [],
      total: 0,
      landTypes: [
        { name: 'GOV', icon: mdiGavel, color: '#1E88E5' },
        { name: 'GM', icon: mdiMapMarker, color: '#FF8C00' },
        { name: 'GMA', icon: mdiMapMarkerOutline, color: '#FFC107' },
        { name: 'GMK', icon: mdiMapMarkerRadius, color: '#2196F3' },
        { name: 'Other', icon: mdiEarthOff, color: '#9E9E9E' },
      ],
    }
  },

  methods: {
    onLandTypeClicked(landType) {
      let landTypes = []
      switch (landType) {
        case 'GOV':
          landTypes = ['GM', 'GMA', 'GMK']
          break
        case 'Total':
          break
        default:
          landTypes.push(landType)
          break
      }
      this.$router.push({ name: 'MIS', params: { tab: 2, filters: { landTypes } } })
    },
    
    async getPlotStats() {
      try {
        this.$loader(true)
        const { data } = await getPlotStats()
        const { landTypes, total } = data
        this.statisticsData = [{ id: 0, name: 'Total', icon: mdiChartBar, color: 'primary', total }].concat(
          this.landTypes.map(l => ({ ...l, ...landTypes.find(landType => landType.name === l.name) })),
        )
        this.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },
  created() {
    this.getPlotStats()
  },
}
</script>
