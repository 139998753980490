<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-medium">Dashboard</h1>
      </v-col>
      <v-col cols="12" v-if="role === 'admin'"> <plot-statistic /> </v-col>
      <v-col cols="12"> <detection-statistic /> </v-col>
      <v-col cols="12" v-if="role !== 'field_officer'"> <complaint-statistic /> </v-col>
      <v-col cols="12" v-if="role === 'admin'"> <user-statistic /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserStatistic from './UserStatistic.vue'
import DetectionStatistic from './DetectionStatistic.vue'
import ComplaintStatistic from './ComplaintStatistic.vue'
import PlotStatistic from './PlotStatistic.vue'
import { mapState } from 'vuex'
export default {
  components: { UserStatistic, DetectionStatistic, ComplaintStatistic, PlotStatistic },
  computed: {
    ...mapState('auth', ['user']),
    role() {
      return this.user?.role?.code || 'field_officer'
    },
  },
}
</script>
