<template>
  <Pie style="width: 100%" :chart-data="data" :chart-options="options" />
</template>
<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  props: {
    data: Object,
    title: String,
  },
  name: 'App',
  components: {
    Pie,
  },
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'left',
          },
        },
      }
    },
  },
}
</script>
