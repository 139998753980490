import request from '@/services/request'

export function getUserStats() {
  return request({
    url: '/dashboard/user-stats',
    method: 'get',
  })
}

export function getChangeDetectionStats(params) {
  return request({
    url: '/dashboard/change-detection-stats',
    method: 'get',
    params,
  })
}

export function getComplaintStats() {
  return request({
    url: '/dashboard/complaint-stats',
    method: 'get',
  })
}

export function getPlotStats() {
  return request({
    url: '/dashboard/plot-stats',
    method: 'get',
  })
}